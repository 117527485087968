import React, { useLayoutEffect, useEffect, useState, useRef } from 'react'

import offertesImage from '../images/functies/circular-component-screenshots/nl/offertes.png'
import bonnenImage from '../images/functies/circular-component-screenshots/nl/bonnen.png'
import facturenImage from '../images/functies/circular-component-screenshots/nl/facturen.png'
import bankenImage from '../images/functies/circular-component-screenshots/nl/banken.png'
import belastingImage from '../images/functies/circular-component-screenshots/nl/belasting.png'
import rapporagesImages from '../images/functies/circular-component-screenshots/nl/rapportages.png'
import headerImage from '../images/boekhoudinglp/headerImage.png'

import saveTimeImage from '../images/functies/split-view/nl/bespaar-tijd.png'
import saveMoneyImage from '../images/functies/split-view/nl/bespaar-geld.png'
import riskImage from '../images/functies/split-view/nl/risico.png'

import { RelativeContainer, ComponentContainer, ColoredBackground } from '../components/shared/landingPages/layoutComponents'
import { Circle, Progress, Card } from '../components/pages/functies/circular'
import { Page, Container, LastButNotLeast, Buttons } from './_functies.styles'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import { Split, Quote, Promotion } from '../components/pages/functies'
import { Phone, Graph, Bank, Tax } from '../images/icons/heroicons'

import Invoice from '../images/englishlp/icons/invoice'
import Quotes from '../images/englishlp/icons/quote'

import { useElementSize, useInterval } from '../hooks'
import Header from '../components/shared/landingPages/header'
import { RegisterButton, Button } from '../components/UI'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'

/**
 * @typedef {Object} Content
 * @property {JSX.Element} content.icon
 * @property {string} content.title
 * @property {string} content.text
 * @property {Blob} content.image
 * @property {string} content.url
 */

/**
 * @type {Content[]}
 */
const content = [
    { Icon: <Quotes />, title: 'Offertes', text: 'Creëer professionele offertes in een handomdraai. In je eigen stijl.', image: offertesImage, url: 'offertes' },
    {
        Icon: <Phone />,
        title: 'Scan bonnen',
        text: 'Scan bonnen en facturen met de Tellow-app op je telefoon. Of upload gemakkelijk je document.',
        image: bonnenImage,
        url: 'bonnen',
    },
    { Icon: <Invoice />, title: 'Factureren', text: 'Maak en verstuur gemakkelijk facturen via je laptop of telefoon.', image: facturenImage, url: 'facturen' },
    { Icon: <Bank />, title: 'Bankintegraties', text: 'Koppel eenvoudig je bank en laad automatisch je transacties in.', image: bankenImage, url: 'koppel-je-rekening' },
    {
        Icon: <Tax />,
        title: 'Digitaal je aangiftes doen',
        text: 'Automatiseer je btw- en inkomstenbelastingaangiftes.',
        image: belastingImage,
        url: 'btw-aangifte',
    },
    {
        Icon: <Graph />,
        title: 'Rapportages',
        text: 'Met de automatisch opgestelde rapportages weet je altijd precies hoe je onderneming ervoor staat.',
        image: rapporagesImages,
        url: 'rapportages',
    },
]

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

/** Custom calculation hook. */
function useCalculatePoints(array, pageWidth, size) {
    const [points, setPoints] = useState([])

    const radius = pageWidth / 2
    const step = (2 * Math.PI) / array.length
    let angle = 0

    /** Calculate. */
    useIsomorphicLayoutEffect(() => {
        const calculation = array.map(() => {
            const x = Math.round(pageWidth / 2 + radius * Math.cos(angle) - size / 2)
            const y = Math.round(pageWidth / 2 + radius * Math.sin(angle) - size / 2)
            angle += step

            return { x: `${x}px`, y: `${y}px` }
        })

        setPoints(calculation)
    }, [pageWidth])

    return points
}

const Functies = () => {
    const [active, setActive] = useState(0)
    const [isPlaying, setIsPlaying] = useState(true)

    const elementRef = useRef(null)
    const { width } = useElementSize(elementRef)

    const elementSize = 250
    const leastAcceptableWidth = 1024
    const pageWidth = width > 800 ? 800 : width
    const points = useCalculatePoints(content, pageWidth, elementSize)

    // @see https://usehooks-ts.com/react-hook/use-interval
    useInterval(
        () => {
            const hasReachedLimit = active === content.length - 1
            const nextCard = active + 1

            setActive(hasReachedLimit ? 0 : nextCard)
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? 3000 : null
    )

    return (
        <Layout>
            <Meta
                keywords={['zzp', 'boekhouden', 'tellow']}
                title="Lees alles over de functionaliteiten van Tellow"
                description="Wil je meer weten over Tellow? Hier vind je uitleg over alle functies die onze software te bieden heeft."
                path="/functies"
                jsonLD={reviewStructuredData}
            />

            <Header
                data={{
                    title: `Alles wat je nodig hebt voor een goede boekhouding`,
                    textCTA: `Ontdek Tellow nu`,
                    headerImage,
                }}
            />

            <ColoredBackground purple>
                <RelativeContainer ref={elementRef}>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Page style={{ '--size': `${pageWidth}px` }}>
                            <Container style={{ '--size': `${pageWidth}px` }}>
                                {points.map((dimensions, index) => (
                                    <Card
                                        key={`${index * 2}`}
                                        setActive={() => {
                                            setActive(index)
                                            setIsPlaying(false)
                                        }}
                                        onHoverLeave={() => {
                                            setIsPlaying(true)
                                        }}
                                        elementSize={elementSize}
                                        content={content[index]}
                                        dimensions={dimensions}
                                        activeIndex={active}
                                        pageWidth={width}
                                        index={index}
                                    />
                                ))}

                                {width > leastAcceptableWidth && <Circle alt={content[active].title} source={content[active].image} />}
                                {width > leastAcceptableWidth && <Progress isPlaying={isPlaying} content={content} activeIndex={active} />}
                            </Container>
                        </Page>
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground gradient purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="0 0 4rem 0">
                        <Promotion
                            copy="Naast het meest gebruiksvriendelijkste boekhoudprogramma, <wbr /> ook één van de meest complete programma's."
                            CTA="Bekijk alle functionaliteiten"
                            url="/prijs#matrix"
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground white>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Quote
                            data={{
                                person: 'Patrick',
                                location: 'Eindhoven',
                                quote: 'Een absolute aanrader voor iedere drukke freelancer!',
                                excerpt:
                                    'Ik houd elke dag mijn gewerkte uren bij via de Tellow app. Aan het einde van de maand verstuur ik een factuur naar mijn klanten. Dit scheelt mij een hoop tijd want dit is in een minuutje geregeld. En ik kom professioneel over door een van de factuurtemplates te gebruiken. Top!',
                                CTA: 'Lees meer reviews',
                                url: '/reviews',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Split
                            placementOfTextBlock="left"
                            data={{
                                source: saveTimeImage,
                                title: 'Bespaar tijd',
                                subtitle: 'Alles wat je nodig hebt bij elkaar',
                                body: 'Met Tellow heb je alle administratieve tools bij elkaar. Je gebruikt Tellow waar je maar wilt via de app of op je laptop. Je transacties worden automatisch geïmporteerd. Scan je bonnetjes en facturen, of upload documenten. Deze worden automatisch herkent, gecategoriseerd en gekoppeld aan je geïmporteerde transacties. Zo is je gehele administratie geautomatiseerd en altijd up-to-date. Aan het einde van het kwartaal doe je jouw btw-aangifte met één druk op de knop. Snel en simpel.',
                                CTA: 'Kies direct een abonnement',
                                url: '/prijs',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="1rem 0">
                        <Split
                            placementOfTextBlock="right"
                            data={{
                                source: saveMoneyImage,
                                title: 'Volledige zekerheid',
                                subtitle: 'Altijd 100% correcte aangifte',
                                body: 'Werk gestructureerd aan je administratie. Mist er een inkoopfactuur, verkoopfactuur of klopt er een transactie niet? We nemen je bij de hand via een overzichtelijk takenlijstje waarin precies staat wat er nog nodig is om jouw administratie volledig te maken. We houden je op de hoogte van alle deadlines en geven inzicht via de rapportages. Wil je toch een boekhouder? Geen probleem. Met Tellow Compleet wordt je gematched aan je eigen persoonlijke boekhouder.',
                                CTA: 'Ontdek Tellow Compleet',
                                url: '/plan/compleet',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Split
                            placementOfTextBlock="left"
                            data={{
                                source: riskImage,
                                title: 'Verdien meer',
                                subtitle: 'Meer tijd en een professionele look',
                                body: 'Besteed minder tijd aan aan je administratie en houdt meer tijd over om voor klanten te werken (of natuurlijk vrije tijd). Met de facturatietool stuur jij professioneel uitziende facturen in een handomdraai. Stuur direct een betaalverzoekje mee zodat je klant makkelijk (en snel) kan betalen. Via de offertefunctionaliteit maak en verstuur je offertes. Keur deze goed en stuur direct uit als factuur.',
                                CTA: 'Ontdek welk abonnement bij jou past',
                                url: '/prijs',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground white>
                <RelativeContainer>
                    <ComponentContainer padding="2rem 0">
                        <LastButNotLeast>
                            <h3>Begin vandaag nog</h3>
                            <p>Bekijk onze abonnementen, of maak direct een account.</p>

                            <Buttons>
                                <Button primary border href="/prijs">
                                    Bekijk abonnementen
                                </Button>
                                <RegisterButton>
                                    Registreer nu
                                    <span className="arrow">{'->'}</span>
                                </RegisterButton>
                            </Buttons>
                        </LastButNotLeast>
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>
        </Layout>
    )
}

export default Functies
